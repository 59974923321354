.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    background-color: #F2F2F2;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;

}

@media only screen and (min-width: 65rem) {
    .cookie-consent-banner {
        width: 100%;
    }

    .cookie-consent-options {
        text-align: center;
        justify-content: center;
    }
}

.cookie-consent-button {
    margin: 8px;
}

.cookie-consent-options label {
    margin: 8px;
}

