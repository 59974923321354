.footerGrid {
    border-top: 1px solid #1E1E1E;
}

.footerContent {
    padding-top: 2rem;
    justify-content: left;
    text-align: left;
}

@media only screen and (min-width: 768px) {
    .footerContent {
        justify-content: center;
        text-align: center;
    }
}

.copyright {
    margin-top: 1rem !important;
    justify-content: center;
    text-align: center;
}
