.layoutContainer {
    display: flex;
    flex-direction: column;
}

.contentArea {
    padding-top: 16px;
    padding-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
    .contentArea {
        padding-top: 24px;
    }
}
