/* Navbar.css */
.navbar {
    position: sticky;
    top: 0;
    z-index: 2;
    overflow: hidden;
    background-color: #F8F8F8;
    border-bottom: 1px solid #1E1E1E;
}

/* Navbar container styles */
.logoContainer {
    display: flex;
    height: 5rem;
    justify-content: center;
    margin-top: 1rem;
}

/* Navbar styles */
.navLinks {
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    text-align: center;
}

/* Navbar link styles */
.navLinks a {
    text-decoration: none;
    color: #1E1E1E;
}

.menuIcon {
    margin-right: 1rem;
    padding-bottom: 1rem;
    margin-top: -3.5rem;
}
