body {
    margin: 0;
    background-color: #F8F8F8;
}

p {
    color: #121212;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: #121212;
}

:root::-webkit-scrollbar {
    display: none;
}

:root {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
