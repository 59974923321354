.homeContent {
    justify-content: center;
    text-align: center;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.homeBanner {
    animation: slideInFromTop 1s ease-out forwards;
    margin-top: -6rem;
    width: 100%;
}

@media (max-width: 768px) {
    .homeBanner {
        margin-top: 0; /* No top margin on mobile */
    }
}
